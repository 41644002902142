// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

import css from "assets/css/components/footer.module.styl"

const Footer = () => (
	<footer className={css.container}>
        <p className={css.copyright}>© {new Date().getFullYear()} SUGAR. All Rights Reserved.</p>
	</footer>
)


export default Footer