import React from "react"

import Layout from "@layouts/index"
import SEO from "@components/seo"
import Footer from "@components/Footer"

import css from '@css/components/404/index.module.styl'

const NotFoundPage = ({location}) => (
    <div>
        <SEO title="404: Not found" />
        <div className={css.container}>
            <main>
                <div className={css.inner}>
                    <h1 className={css.title}>NOT FOUND</h1>
                    <p className={css.text}>ページが存在しません。</p>
                </div>
            </main>
            <footer className={css.footer}>
                <p className={css.copyright}>© {new Date().getFullYear()} SUGAR. All Right Reserved.</p>
            </footer>
        </div>
    </div>
)

export default NotFoundPage
